import React, { useState, useEffect, useRef } from "react"
import { PageRenderer, graphql, navigate } from "gatsby"
import he from "he"
import PropTypes from "prop-types"

import Seo from "../components/seo"
import RenderBlock from "../utils/render-block"
import Link from "../utils/link"
import Fade from "react-reveal/Fade"

import ImageComponent from "../components/Common/ImageComponent"
import WingspanLogo from "../assets/images/Vectors/WingspanFullMark.svg"
import CloseIcon from "../assets/images/Vectors/times.svg"
import StandAloneLogo from "../assets/images/Vectors/Shape.svg"
import DownloadIcon from "../assets/images/Vectors/Download.svg"
import ArrowIcon from "../assets/images/Vectors/np_arrow-right_888647_FFFFFF-1.svg"
import "./ServicesPopupTemplate.scss"

const ServicesPopupTemplate = ({ data }) => {
  const {
    subHeading,
    heading,
    servicePopupImage,
    nextServiceLink,
    pdfDownlaod,
  } = data.wpService.acfServices

  const popupImageData = {
    url: servicePopupImage.localFile.publicURL,
  }

  const serviceOverlay = useRef(null)

  const [path1, setPath1] = useState()

  useEffect(() => {
    typeof window !== `undefined`
      ? setPath1(window.location.pathname)
      : setPath1("")
  }, [path1])

  const navigateBack = (e) => {
    e.preventDefault()
    const position = parseInt(sessionStorage.getItem("positionInitial"))
    sessionStorage.removeItem("positionInitial")
    if (position) {
      sessionStorage.setItem("position", position)
    }
    navigate(sessionStorage.getItem("prevLocation"))
  }

  return (
    <div>
      <PageRenderer key={"/services"} location={{ pathname: "/services" }} />
      <div
        ref={serviceOverlay}
        onLoad={() => {
          serviceOverlay.current.focus()
        }}
        className="overlay-page"
        tabIndex="0"
      >
        <div className="content">
          <Fade>
            <div className="content__header content__container">
              <Link to="/" title="Wingspan Capital" className="header__logo">
                <img src={WingspanLogo} />
              </Link>

              <div className="content__closeTrigger">
                <button
                  onClick={(e) => {
                    navigateBack(e)
                  }}
                >
                  <img
                    className="content__close"
                    src={CloseIcon}
                    alt="close button"
                  />
                </button>
              </div>
            </div>
          </Fade>
          <div className="content__container">
            <Fade>
              <div className="popup__image" style={{ height: "550px" }}>
                <ImageComponent content={popupImageData} isBanner />
              </div>
            </Fade>
            <div className="service">
              <Fade>
                <div className="service__header">
                  <div className="service__headerTitleContainer">
                    <h2 className="service__headerTitle">{subHeading}</h2>
                  </div>
                  <div className="service__headerWrapper">
                    <h3 className="service__heading">{heading}</h3>
                  </div>
                </div>
              </Fade>
              <div className="popupContent">
                <div className="popup">
                  <div className="popup__download">
                    { pdfDownlaod?.localFile?.publicURL &&
                      <Fade bottom>
                        <div className="popup__downloadWrapper">
                          <a
                            href={pdfDownlaod?.localFile?.publicURL}
                            download
                            className="popup__heading"
                          >
                            DOWNLOAD PDF
                          </a>
                          <a href={pdfDownlaod?.localFile?.publicURL} download>
                            <img
                              className="popup__downloadImage"
                              loading="lazy"
                              src={DownloadIcon}
                              alt="download"
                            />
                          </a>
                        </div>
                      </Fade>
                    }
                  </div>
                  <div className="popup__content">
                    <div className="popup__contentWrapper">
                      {data.wpService && <Seo title={he.decode(data.wpService.seo.title)} description={data.wpService.seo.metaDesc} />}

                      {data.wpService &&
                        data.wpService.acf.contentBlocks &&
                        data.wpService.acf.contentBlocks.map((el, i) =>
                          RenderBlock(el.fieldGroupName, el, i, "service")
                        )}
                      <Fade>
                        <div className="popup__buttonContainer">
                          <div className="popup__buttonContainerWrapper">
                            <div className="popup__nextServiceContainer">
                              <Link
                                to={"/services" + nextServiceLink.url}
                                className="popup__nextService"
                              >
                                {he.decode(nextServiceLink.title)}
                              </Link>
                              <Link to={"/services" + nextServiceLink.url}>
                                <img
                                  className="popup__arrowImage"
                                  loading="lazy"
                                  src={ArrowIcon}
                                  alt="arrow"
                                />
                              </Link>
                            </div>

                            <div>
                              <button
                                onClick={(e) => {
                                  navigateBack(e)
                                }}
                                className="popupContent__closeTrigger"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service__footer">
                <div className="service__emptyContent"></div>
                <Fade>
                  <div className="service__footerWrapper">
                    <Link to="/">
                      <img
                        className="service__standaloneLogo"
                        src={StandAloneLogo}
                        alt="standaloneLogo"
                      />
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String) {
    wpService(id: { eq: $id }) {
      slug
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        contentBlocks {
          ... on WpService_Acf_ContentBlocks_ServicesPopupDescription {
            fieldGroupName
            contentContainer {
              content
              fieldGroupName
            }
          }
          ... on WpService_Acf_ContentBlocks_ServicesPopupBenifits {
            fieldGroupName
            benifitsContainer {
              benifit
              fieldGroupName
            }
          }
        }
      }
      acfServices {
        fieldGroupName
        heading
        subHeading

        nextServiceLink {
          title
          url
        }
        pdfDownlaod {
          localFile {
            publicURL
          }
        }
        serviceDescriptionForMobile
        servicePopupImage {
          localFile {
            publicURL
          }
        }
      }
      slug
      title
    }
  }
`

ServicesPopupTemplate.propTypes = {
  data: PropTypes.object,
  subHeading: PropTypes.string,
  heading: PropTypes.string,
  pdfDownlaod: PropTypes.object,
  servicePopupImage: PropTypes.object,
  nextServiceLink: PropTypes.object,
}

export default ServicesPopupTemplate
